import _ from "lodash"
import React from "react"
import * as ReactDOM from "react-dom"

import { parse } from "@babel/parser"
import * as Babel from "@babel/standalone"
import dayjs from "dayjs"
import Link from "next/link"
import { getSnippet } from "../../features/codeEditor/snippets"

export const executeFromSnippet = (snippetName: string, context: any = {}) => {
  const snippet = getSnippet(snippetName)
  if (!snippet) return undefined
  return runInRestrictedEnvironment(snippet, context)
}

function isJSX(code) {
  code = `
        (function() {
            ${code}
        })()
    `
  try {
    parse(code, {
      sourceType: "module",
      plugins: ["jsx"],
    })
    return true
  } catch (error) {
    return false
  }
}

export function runInRestrictedEnvironment(code: string, context: any = {}) {
  if (isJSX(code)) {
    code = `
            (function() {
                ${code}
            })()
        `

    code = Babel.transform(code, { presets: ["env", "react"] }).code

    code = code.replace('"use strict";\n', "")
  }

  const approvedLibraries = {
    lodash: _,
    React,
    ReactDOM,
    Link,
    typeof: (value) => typeof value,
    _typeof: (value) => typeof value,
  }

  const _context = {
    console: {
      log: (...args) => console.log(...args),
    },
    dayjs: dayjs,
    require: (libName) => {
      if (approvedLibraries[libName]) {
        return approvedLibraries[libName]
      } else {
        throw new Error(`Library ${libName} is not whitelisted.`)
      }
    },
    executeSnippet: (snippet) => {
      return executeFromSnippet(snippet, _context)
    },
    __result__: undefined,
    __error__: undefined,
    ...context,
    ...approvedLibraries,
  }

  const obscuredGlobals = [
    "eval",
    "Function",
    "window",
    "document",
    "fetch",
    "XMLHttpRequest",
    "setTimeout",
    "setInterval",
  ]

  for (let prop of obscuredGlobals) {
    _context[prop] = null
  }

  const wrappedCode = `
        with (context) {
            context.__result__ = ${code};
        }
    `

  try {
    new Function("context", wrappedCode)(_context)
  } catch (error) {
    console.error(error)
    // _context.__error__ = error.message;
  }

  return _context.__result__
}
