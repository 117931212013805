export const javascriptSnippets = {
  "Contract signed (revenue)": `
    const filterEvents = events?.filter((e) => {
        return dayjs(e?.properties?.start_date || e.created).format('YYYY-MM') <= date.format('YYYY-MM');
    });

    const sum =  _.sum(
        filterEvents.map(({ properties }) => {
            const { value } = formatMoneyInputs({ value: properties?.hourly_rate });
            if (isDateBetween(date, properties?.start_date, properties?.end_date)) return 0;
            if (isNaN(value)) return 0;
            return Number(value) * (properties?.units_per_month ?? 0);
        })
    );

    return sum / 100;
    `,
  "Task count over time": `

    const filterTasks = tasks?.filter((e) => {
        return dayjs(e.created).format('YYYY-MM') === date.format('YYYY-MM');
    });

    return filterTasks.length;
    `,
  "Tasks by status": `

    const uidToNameMapping = {
        '8b9b1ff1-f3f3-4dd7-b6d7-464453ead1da': 'Avlyst kunde',
        'a1d1ea61-d3b1-43db-80be-40f62f620835': 'Ikke levert bestilling',
        '78143afc-6eb7-4465-b458-5b684749b9e3': 'Ikke levert ingen kandidat',
        '5ab9a30a-562a-4194-b300-76690191bd82': 'Tapt',
        'e12f636f-844a-4cd8-ab79-d97b34c2239c': 'Kontrakt fra kunde arkivert',
        '8370a75f-fddb-4d26-8bd1-cfdb3b29ad7b': 'Underleverandørkontrakt arkivert',
        '3ebab991-2084-4299-b93a-7a87976b3f04': 'Underleverandørkontrakt signert',
        '06d059b3-284d-4f25-87d1-bd4875bcefb5': 'Vunnet',
        '2bb26b2d-9cfb-4daa-9a82-3cc1c12db5d1': 'Opprett underleverandørkontrakt',
        'c40eb580-2f60-4047-b67c-0d99adba39c0': 'Mottatt kontrakt fra kunde',
        '312be61a-b9e2-4685-9954-2ac599afe5ae': 'Tilbud levert til kunde',
        'e6d8d964-9aec-4367-8c4b-5e3f30487f15': 'Distribuert',
      };

      const tasksByStatus = _.groupBy(tasks, 'status_frem_til_tilbudslevering');

        Object.keys(tasksByStatus).forEach((key) => {
            tasksByStatus[uidToNameMapping[key] ?? key] = tasksByStatus[key];
            delete tasksByStatus[key];
        });

      return tasksByStatus;
    `,

  "Tasks by status RT": `
    const uidToNameMapping = {
        '608ef642-66c4-4fa0-a1a3-5ede90b9e21a': 'Utgår',
        '5bf15b3a-18f1-4f21-8192-db8907d20476': 'Underleverandørkontrakt arkivert',
        'ecaa447-73e0-4fa9-a9ad-31ac41ee5739': 'Underleverandør signert',
        '9a10ce00-9514-4f55-928d-b18f86ae00c4': 'Opprett underleverandørkontrakt',
        '3ca77e77-f9c2-461d-a9f1-b8923a5866d0': 'Mottatt kontrakt fra kunde',
        '918ee36e-5a96-4766-9b88-1bc4d9fe7f31': 'Tilbud levert til kunde',
        '7bea776e-1a91-4546-94b1-72c6433b9b8b': 'Vunnet',
        'f68a85ff-9847-4819-861b-53e0e8bc1299': 'Tapt',
        '1e8f3038-306c-47f7-972b-906c095a226f': 'Igangsat',
      };

      const tasksByStatus = _.groupBy(tasks, 'status');

        Object.keys(tasksByStatus).forEach((key) => {
            tasksByStatus[uidToNameMapping[key] ?? key] = tasksByStatus[key];
            delete tasksByStatus[key];  
        });

      return tasksByStatus;
    `,

  "Tasks by status SC": `
    const uidToNameMapping = {
        'd1efe12e-c0ca-4b26-9adc-961efd6b9374': 'Supplier contract archived',
        '3646e180-22d6-4108-aa97-3291b2aecb43': 'Won',
        '72289e17-5890-4b0f-88a2-26570c301279': 'Supplier contract signed',
        '6df103f9-a933-4b05-bea5-4644d4487663': 'In Progress',
        '1dcc0269-0426-449a-aed1-89c62ad4e2ea': 'Create supplier contract',
        '0c94764d-9755-4e85-9833-3c73269335be': 'Offer delivered to client',
        '51df26aa-14be-4c23-b30f-49d513570c44': 'Received contract from customer',
        '7a48a201-044c-420e-a5c8-9580659e5e5f': 'Lost',
      };

      const tasksByStatus = _.groupBy(tasks, 'status');

        Object.keys(tasksByStatus).forEach((key) => {
            tasksByStatus[uidToNameMapping[key] ?? key] = tasksByStatus[key];
            delete tasksByStatus[key];  
        });

      return tasksByStatus;
    `,

  "Tasks by status VV": `
    const uidToNameMapping = {
        'a64e97b4-b368-4736-bf7b-c5dd6a6af315': 'Underleverandørkontrakt arkivert',
        '872a7e91-0ae8-4de6-bf12-7a87a4366926': 'Underleverandør signert',
        '65d32621-4031-4c61-98aa-0d6ee876c65a': 'Mottatt kontrakt fra kunde',
        'a9f66cb9-9caf-4861-85c4-c0851731861a': 'Tilbud levert til kunde',
        'd9fbef80-8ffe-4196-a132-3e77229bfcd8': 'Vunnet',
        'a619ad2a-7a0d-4e5e-826a-6e13768b7322': 'Tapt',
        '497014b0-42ca-4ea4-8b54-141a34112c5c': 'Igangsat',
      };

      const tasksByStatus = _.groupBy(tasks, 'status');

        Object.keys(tasksByStatus).forEach((key) => {
            tasksByStatus[uidToNameMapping[key] ?? key] = tasksByStatus[key];
            delete tasksByStatus[key];  
        });

      return tasksByStatus;
    `,

  "Tasks by status CT": `
    const uidToNameMapping = {
        '4b7dd4c7-1a19-4ce8-b77e-9b4221c5fb16': 'Supplier contract archived',
        '654aa635-4e86-4f4c-81ef-ab9d66cd2b56': 'Supplier contract signed',
        '7f4c6532-229c-4c6d-8fbc-4a388b58b179': 'Create supplier contract',
        '39414df4-a0a6-4a00-840f-80dc72a4dd37': 'Received contract from customer',
        '966ad25c-d234-442b-af25-68521e712436': 'Offer delivered to client',
        'a228c9e4-8af7-40ed-a831-cdbd3595a2b4': 'Won',
        '5ab7c605-a1a4-4618-bcf2-708dc7271c34': 'Lost',
        '15bd3686-ab56-4bea-b31d-f07648984380': 'In Progress',

      };

      const tasksByStatus = _.groupBy(tasks, 'status');

        Object.keys(tasksByStatus).forEach((key) => {
            tasksByStatus[uidToNameMapping[key] ?? key] = tasksByStatus[key];
            delete tasksByStatus[key];  
        });

      return tasksByStatus;
    `,
  "Tasks by status GT": `
    const uidToNameMapping = {
        'f51e09ea-c0ec-4dec-b273-97859438bd6b': 'Lead Identified',
        'a4684d79-6856-4140-af9b-80b254990910': 'Initiated proposal work',
        'e2e2abd5-5fde-475f-9434-1fe402d16f91': 'Offer delivered to customer',
        'f9daefd1-126c-43d6-b106-c2ed51e8cdaa': 'Informal confirmation of win',
        '37331952-6e7e-43b1-87b7-6b13c372b885': 'Won (contract signed)',
        '02039939-2f79-4e29-adc0-f17b9d3b8f54': 'Lost',

      };

      const tasksByStatus = _.groupBy(tasks, 'status');

        Object.keys(tasksByStatus).forEach((key) => {
            tasksByStatus[uidToNameMapping[key] ?? key] = tasksByStatus[key];
            delete tasksByStatus[key];  
        });

      return tasksByStatus;
    `,
  "Tasks by status ST": `
    const uidToNameMapping = {
        '7f2a49cb-c935-45f5-87a2-52a0f305d25c': 'Tilbud levert til kunde',
        '7aa7af03-e456-45f4-897e-29af51e463f2': 'Vunnet',
        '49063782-dacc-436f-a41e-37adda3749cc': 'Tapt',
        'ccfdace2-e94f-4bc3-b888-a70d90e25f6a': 'Igangsat',
      };

      const tasksByStatus = _.groupBy(tasks, 'status');

        Object.keys(tasksByStatus).forEach((key) => {
            tasksByStatus[uidToNameMapping[key] ?? key] = tasksByStatus[key];
            delete tasksByStatus[key];  
        });

      return tasksByStatus;
    `,
  // Talents: `

  // const filterTasks = tasks?.filter((e) => {
  //     return dayjs(e.created).format('YYYY-MM') <= date.format('YYYY-MM');
  // });

  // return filterTasks.length;
  // `,
  // contract_count: `

  // const filterTasks = contracts?.filter((e) => {
  //     return dayjs(e.start_date).format('YYYY-MM') <= date.format('YYYY-MM');
  // });

  // return filterTasks.length;
  // `,
  "Contract signed (cut)": `
            const showLine = true;
            if (!showLine) return null;
            return _.sum(
                events.map(({ properties }) => {
                    const { value } = formatMoneyInputs({ value: properties?.hourly_rate });
            
                    if (isDateBetween(date, properties?.start_date, properties?.end_date)) return 0;
                    if (isNaN(value)) return 0;
                    const cutPercentage = properties?.cut_percentage ?? 0;
            
                    const rate = Number(value) * (cutPercentage / 100);
            
                    return rate * (properties?.units_per_month ?? 0);
                })
            );
    `,
}

export const getSnippet = (name: string) => {
  return javascriptSnippets[name]
}
